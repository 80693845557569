import { render, staticRenderFns } from "./RestaurantsModuleComponent.vue?vue&type=template&id=50a9f214&scoped=true&"
import script from "./RestaurantsModuleComponent.vue?vue&type=script&lang=js&"
export * from "./RestaurantsModuleComponent.vue?vue&type=script&lang=js&"
import style0 from "./RestaurantsModuleComponent.vue?vue&type=style&index=0&id=50a9f214&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a9f214",
  null
  
)

export default component.exports