<template>
    <restaurants-module-component></restaurants-module-component>
</template>

<script>
    import RestaurantsModuleComponent from "@/components/admin/modules/RestaurantsModuleComponent";

    export default {
        name: "RestaurantsModule",
        title: "Gestión de Restaurantes | Turismo BC",
        components: {RestaurantsModuleComponent}
    }
</script>

<style scoped>

</style>